import { queryOptions, useQuery } from "@tanstack/react-query";
import { assetsApiRequest } from "~api/api-request";
import { Graphics } from "~graphics/graphics.model";
import { useRequiredParams } from "~utils/url";
import { ApiResponse } from "~api/api-response.model";
import { useSuspenseQueryDeferred } from "~utils/query-hooks";
import { Level } from "./level.model";

type LevelParams = {
  levelId: number;
  companyId: number;
};

type LevelDetailParams = {
  levelId?: number;
  companyId: number;
  includeParent?: boolean;
};

type LevelListParams = {
  isBuilding?: boolean;
  companyId: number;
  parentId?: number | null;
  nested?: boolean;
};

export const levelQueries = {
  sublevels: (params: LevelParams) =>
    queryOptions({
      queryKey: ["sublevels", params],
      queryFn: () => fetchSublevels(params),
    }),
  levels: (params: LevelListParams) =>
    queryOptions({
      queryKey: ["levels", params],
      queryFn: () => fetchLevels(params),
    }),
  detail: (params: LevelDetailParams) =>
    queryOptions({
      queryKey: ["levels", "level", params],
      queryFn: () => fetchLevel(params),
    }),
  graphics: (params: LevelParams) =>
    queryOptions({
      queryKey: ["level-graphics", params],
      queryFn: () => fetchLevelGraphics(params),
    }),
};

// Queries

export function useLevelsSuspenseQuery(
  params: Omit<LevelListParams, "companyId">,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useSuspenseQueryDeferred(
    levelQueries.levels({ ...params, companyId }),
  );
}

export function useSublevelsQuery({
  levelId,
  enabled = true,
}: {
  levelId: number;
  enabled?: boolean;
}) {
  const { companyId } = useRequiredParams({ companyId: "number" });

  return useQuery({
    enabled,
    ...levelQueries.sublevels({ companyId, levelId }),
  });
}

export function useLevelQuery(
  levelId?: number,
  queryOption?: { enabled: boolean; includeParent?: boolean },
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery({
    ...queryOption,
    ...levelQueries.detail({
      companyId,
      levelId,
      includeParent: queryOption?.includeParent,
    }),
  });
}

export function useLevelGraphicsQuery({
  levelId,
  enabled = true,
}: {
  levelId: number;
  enabled?: boolean;
}) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery({
    enabled,
    ...levelQueries.graphics({ companyId, levelId }),
  });
}

// Fetchers

async function fetchLevels({ companyId, ...params }: LevelListParams) {
  return assetsApiRequest
    .get<
      ApiResponse<Level[]>
    >(`analytics/companies/${companyId}/levels`, { params: { ...params, parentId: params.parentId === null ? "null" : params.parentId } })
    .then((resp) => resp.data.data.map((l) => new Level(l)));
}

async function fetchSublevels(params: LevelParams) {
  return assetsApiRequest
    .get<
      ApiResponse<Level[]>
    >(`analytics/companies/${params.companyId}/levels/${params.levelId}/levels`)
    .then((resp) => resp.data.data.map((l) => new Level(l)));
}

async function fetchLevel(params: LevelDetailParams) {
  return assetsApiRequest
    .get<ApiResponse<Level>>(
      `analytics/companies/${params.companyId}/levels/${params.levelId}`,
      {
        params: { includeParent: params.includeParent },
      },
    )
    .then((resp) => new Level(resp.data.data));
}

export async function fetchLevelGraphics(params: LevelParams) {
  return assetsApiRequest
    .get<
      ApiResponse<Graphics[]>
    >(`analytics/companies/${params.companyId}/levels/${params.levelId}/graphics`)
    .then((resp) => resp.data.data.map((d) => new Graphics(d)));
}
