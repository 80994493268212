import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCompanies } from "../company/company.queries";
import { fetchCurrentUser, useCurrentUserQuery } from "./user.queries";
import { adminApiRequest } from "../api/api-request";
import { Permissions } from "../const";

type User = {
  permissions: string[];
  id: number;
  email: string;
  createdAt: Date;
  active: boolean;
  firstName: string | null;
  lastName: string | null;
  language: string;
  fullName: string;
};
interface UserContextProps {
  user?: User;
  resetContext: () => void;
}
export const UserContext = React.createContext<UserContextProps | undefined>(
  undefined,
);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("UserContext must be used within userContextProvider");
  }
  return context;
};

const permissionBasedRoute = {
  [Permissions.GRAPHIC_VIEW]: "graphics",
  [Permissions.ALARMS_VIEW]: "alarms",
  [Permissions.HISTORY_VIEW]: "history",
  [Permissions.POINTS_VIEW]: "points",
  [Permissions.EVENTLOG_VIEW]: "logs/event",
  [Permissions.ALARMLOG_VIEW]: "logs/alarm",
  [Permissions.DEVICES_VIEW]: "devices",
  [Permissions.CALENDARS_VIEW]: "schedule/calendars",
};

const checkPermission = (permissions, companyId) => {
  for (const [perm, route] of Object.entries(permissionBasedRoute)) {
    if (
      window.location.pathname.includes(route) &&
      permissions.includes(perm)
    ) {
      return window.location.pathname + window.location.search;
    }
  }
  return `/company/${companyId}/overview?isCollapsed=true`;
};

export const UserContextProvider = ({ children }) => {
  useCurrentUserQuery();
  const [currentUser, setCurrentUser] = useState<User>();
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      if (params.companyId) {
        await adminApiRequest.refreshAccessToken();
        const user = await fetchCurrentUser();
        setCurrentUser(user);
        const path = checkPermission(user.permissions, params.companyId);
        navigate(path, { replace: true });
      } else {
        // fetch company specific detail
        const companies = await fetchCompanies();
        if (companies.length > 0) {
          const companyId = companies[0].id;
          navigate(`/company/${companyId}`, { replace: true });
          await adminApiRequest.refreshAccessToken();
          const user = await fetchCurrentUser();
          setCurrentUser(user);
        } else {
          navigate("/logout", { replace: true });
        }
      }
    })();
  }, [navigate, params.companyId]);

  const resetContext = () => {
    if (currentUser) {
      setCurrentUser({ ...currentUser, permissions: [] });
    }
  };

  const value = useMemo(() => {
    return { user: currentUser, resetContext };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
