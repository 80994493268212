import { Outlet } from "react-router-dom";
import { DeviceTree } from "./device-tree";
import { SidePanelLayout } from "../common/page-layout";

export function GraphicsRoute() {
  return (
    <>
      <SidePanelLayout showBorder isScrollable isCollapsible>
        <DeviceTree />
      </SidePanelLayout>
      <Outlet />
    </>
  );
}
