import { queryOptions, useQuery } from "@tanstack/react-query";
import { assetsApiRequest } from "~api/api-request";
import { ApiResponse } from "~api/api-response.model";
import { useRequiredParams } from "~utils/url";
import { Graphics, GraphicsAttachment } from "./graphics.model";
import { QueryOptions } from "../utils/consts";
import { fetchDevices } from "../device/device.queries";
import { fetchLevelGraphics } from "../level/level.queries";
import { searchInGraphicItems } from "./graphics.utils";
import { PointsHistoryParams } from "../point/point-history/point-history.queries";
import { Point, PuckValue } from "../point/point.model";

type GraphicsParams = {
  companyId: number;
  graphicsId: number;
};

type GraphicsIdParams = {
  companyId: number;
  deviceId: number;
  levelId: number;
  path: string;
};
export const graphicsQueries = {
  one: (params: GraphicsParams) =>
    queryOptions({
      queryKey: ["devices-graphics", params],
      queryFn: ({ signal }) => fetchGraphics(params, signal),
    }),
  attachments: (params: GraphicsParams, options?: QueryOptions) =>
    queryOptions({
      queryKey: ["graphics-attachments", params],
      queryFn: ({ signal }) => fetchGraphicsAttachments(params, signal),
      ...options,
    }),
  idByLevelOrDevice: (params: GraphicsIdParams, options?: QueryOptions) =>
    queryOptions({
      queryKey: ["graphics-id", params],
      queryFn: () => fetchGraphicsIdByLevelOrDevice(params),
      ...options,
    }),
  history: (params: PointsHistoryParams, options?: QueryOptions) =>
    queryOptions({
      queryKey: ["point-history", params],
      queryFn: ({ signal }) => fetchGraphicsHistory(params, signal),
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }),
};

export function useGraphicsQuery({
  graphicsId,
  enabled = true,
}: {
  graphicsId: number;
  enabled?: boolean;
}) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery({
    enabled,
    ...graphicsQueries.one({ companyId, graphicsId }),
  });
}

export function useGraphicsHistoryQuery(
  {
    from,
    to,
    pointIds,
  }: {
    from?: Date;
    to?: Date;
    pointIds: number[];
  },
  options?: QueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(
    graphicsQueries.history({ pointIds, companyId, from, to }, options),
  );
}

export function useGraphicsAttachmentsQuery(
  graphicsId: number,
  options?: QueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(
    graphicsQueries.attachments({ companyId, graphicsId }, options),
  );
}

export function useGraphicsIdByPathQuery(
  params: Omit<GraphicsIdParams, "companyId">,
  options?: QueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(
    graphicsQueries.idByLevelOrDevice({ ...params, companyId }, options),
  );
}

async function fetchGraphics(params: GraphicsParams, signal?: AbortSignal) {
  return assetsApiRequest
    .get<
      ApiResponse<Graphics>
    >(`analytics/companies/${params.companyId}/graphics/${params.graphicsId}`, { signal })
    .then((resp) => new Graphics(resp.data.data));
}

export async function fetchGraphicsAttachments(
  params: GraphicsParams,
  signal?: AbortSignal,
) {
  return assetsApiRequest
    .get<
      ApiResponse<GraphicsAttachment[]>
    >(`analytics/companies/${params.companyId}/graphics/${params.graphicsId}/attachments`, { signal })
    .then((resp) =>
      resp.data.data.map((attachment) => new GraphicsAttachment(attachment)),
    );
}

async function fetchGraphicsIdByLevelOrDevice(params: GraphicsIdParams) {
  const { companyId, deviceId, levelId, path } = params;
  const device = await fetchDevices({
    companyId,
    pageSize: 1,
    page: 1,
    deviceIds: [deviceId],
  });
  const level = await fetchLevelGraphics({ companyId, levelId });
  const graphic = device[0].graphics.find(
    (graphic) =>
      searchInGraphicItems({ items: graphic.items, searchPath: path }) ||
      level.find((graphic) =>
        searchInGraphicItems({ items: graphic.items, searchPath: path }),
      ),
  );

  return graphic?.id;
}

export type GraphicValue = PuckValue;
export type GraphicHistoryItem = Record<string, GraphicValue[]>;
type GraphicsHistoryResponse = {
  point: Point;
  values: GraphicValue[];
}[];

async function fetchGraphicsHistory(
  { pointIds, companyId, from, to }: PointsHistoryParams,
  signal?: AbortSignal,
): Promise<GraphicHistoryItem> {
  if (pointIds.length === 0 || !from || !to) {
    return {};
  }

  const history: GraphicHistoryItem = {};

  await assetsApiRequest
    .get<ApiResponse<GraphicsHistoryResponse>>(
      `analytics/companies/${companyId}/points/history`,
      {
        signal,
        params: {
          pointIds: pointIds.join(","),
          from: from.toISOString(),
          to: to.toISOString(),
        },
      },
    )
    .then((resp) => {
      resp.data.data.forEach((p) => {
        p.values.forEach((value) => {
          // Add normal points to our 'history' map
          const pointName = p.point.name;
          const pointValue = historyValueToGraphicValue(
            p.point.name,
            p.point,
            value,
          );
          addValuesToHistory(pointName, pointValue, history);

          // Add limit values to our 'history' map
          value.extension?.limits?.forEach((limit) => {
            const limitName = `${p.point.name}:${limit.index}`;
            const limitValue = historyValueToGraphicValue(limitName, p.point, {
              ...value,
              value: limit.value,
              lockState: limit.lockState,
            });
            addValuesToHistory(limitName, limitValue, history);
          });

          // Add control values to our 'history' map
          Object.entries(value.extension?.control ?? {}).forEach(
            ([key, val]) => {
              const controlName = `${p.point.name}#CONTROL#${key}`;
              const controlValue = historyValueToGraphicValue(
                controlName,
                p.point,
                {
                  ...value,
                  value: +val,
                },
              );
              addValuesToHistory(controlName, controlValue, history);
            },
          );
        });
      });
    });
  return history;
}

function addValuesToHistory(
  key: string,
  value: GraphicValue,
  history: GraphicHistoryItem,
) {
  if (Object.hasOwn(history, key)) {
    history[key].push(value);
  } else {
    history[key] = [value];
  }
}
function historyValueToGraphicValue(
  pointName: string,
  point: Point,
  value: GraphicValue,
): GraphicValue {
  return {
    value: value.value,
    alarmState: value.alarmState,
    errorState: value.errorState,
    lockState: value.lockState,
    timestamp: value.timestamp,
    pointName,
    deviceId: point.device.externalId,
    unit: String(point.unit),
    decimals: point.decimals,
    state:
      (point?.stateTexts || []).find(
        (stateText) => stateText.value === value.value,
      )?.label ?? null,
    deviceState: value.deviceState,
  };
}
