import { useLingui } from "@lingui/react";
import { Outlet } from "react-router-dom";
import { css, styled } from "styled-components";
import { useParsedSearchParams, usePersistSearchParams } from "~utils/url";
import { Icon, ScrollableContainer } from "@fidelix/fx-miranda";
import { Button } from "react-aria-components";
import useMeasure from "react-use-measure";
import { Navbar } from "./nav-bar";
import { QueryBoundary } from "./query-boundary";

export function PageLayout() {
  useLingui();
  usePersistSearchParams();

  return (
    <Layout>
      <Navbar />
      {/* NOTE: this id is used to calculate the width of the main content area */}
      <Main id="main">
        <QueryBoundary>
          <Outlet />
        </QueryBoundary>
      </Main>
    </Layout>
  );
}

type SidePanelProps = {
  showBorder?: boolean;
  isScrollable?: boolean;
  isCollapsible?: boolean;
  children?: React.ReactNode;
};
export function SidePanelLayout({
  children,
  showBorder,
  isScrollable,
  isCollapsible,
}: SidePanelProps) {
  const { parsedParams, setSearchParams } = useParsedSearchParams({
    isCollapsed: { type: "string", defaultValue: undefined },
  });
  const [measureRef, { width }] = useMeasure();
  const isCollapsed = parsedParams.isCollapsed === "true";
  const left = isCollapsed ? 36 : width + 70;

  const onPressCollapse = () => {
    setSearchParams((params) => {
      if (!isCollapsed) {
        params.set("isCollapsed", "true");
      } else {
        params.delete("isCollapsed");
      }
    });
  };

  return (
    <>
      {showBorder && <Border style={{ left }} $showBorder={showBorder} />}
      {isCollapsible && (
        <CollapseButton
          style={{ left: left - 9, top: window.innerHeight / 2 - 36 }}
          onPress={onPressCollapse}
        >
          <Icon
            icon={parsedParams.isCollapsed ? "chevronRight" : "chevronLeft"}
          />
        </CollapseButton>
      )}
      <SidePanel $isCollapsed={isCollapsed} $isScrollable={isScrollable}>
        <div ref={measureRef}>{isCollapsed ? null : children}</div>
      </SidePanel>
    </>
  );
}

const transition = "all 0.15s ease-out allow-discrete";

const CollapseButton = styled(Button)`
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.colors.mutedNeutralBackground};
  width: 18px;
  height: 72px;
  border: 1px solid ${(p) => p.theme.colors.lightNeutral};
  border-radius: 30px;
  z-index: 1000;
  box-shadow: 1px 1px 2px ${(p) => p.theme.colors.black}26;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: ${(p) => p.theme.colors.mutedNeutralBackground};
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

const Border = styled.div<{ $showBorder?: boolean }>`
  position: sticky;
  border-right: ${(p) =>
    p.$showBorder ? `1px solid ${p.theme.colors.lightNeutral}` : "none"};
`;

const Main = styled.main`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  background-color: ${(p) => p.theme.colors.white};
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;

export const MainPanelLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${(p) => p.theme.spacing.medium}px;
  gap: ${(p) => p.theme.spacing.medium}px;
`;

const SidePanel = styled(ScrollableContainer)<{
  $isScrollable?: boolean;
  $isCollapsed?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(p) => p.theme.spacing.medium}px;
  padding-left: ${(p) => p.theme.spacing.small}px;
  padding-right: ${(p) => p.theme.spacing.medium}px;
  gap: ${(p) => p.theme.spacing.medium}px;
  min-width: 17.5%;
  overflow-y: hidden;
  transition: ${transition};
  scrollbar-gutter: stable;
  ${(p) =>
    p.$isCollapsed === true &&
    css`
      overflow-x: hidden;
      width: 0px;
      max-width: 0px;
      min-width: 0px;
      padding-right: ${(p) => p.theme.spacing.xxsmall}px;
    `}
  ${(p) =>
    p.$isScrollable === true &&
    css`
      overflow-y: ${p.$isCollapsed ? "hidden" : "auto"};
      // Calculate the height of the scrollable portion
      // 72px is the height of top navigation bar
      // TODO: Instead of hardcoding the height of the navigation bar maybe
      //       get a ref to the navigation bar and find out the height from there
      max-height: ${(p) => window.innerHeight - 72 - p.theme.spacing.xxlarge}px;
      background-clip: padding-box;
    `};
`;
