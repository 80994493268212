import { fetchDeviceByExternalId } from "~device/device.queries";
import { Level } from "~level/level.model";
import { MenuItems } from "./graphics.model";
import { PuckValue } from "../point/point.model";

export const isExternalIdIncluded = (pointId: string) => pointId.includes(".");

export const findLevelById = (level: Level, id: number): Level | null => {
  if (level.id === id) return level;
  return level.parent ? findLevelById(level.parent, id) : null;
};

export const getBuildingIds = (level): number[] => {
  const levelIds = [level.id];

  if (level.parent) {
    const parentIds = getBuildingIds(level.parent);
    const buildingIds = parentIds.filter(
      (parentLevelId) => findLevelById(level, +parentLevelId)?.isBuilding,
    );
    levelIds.push(...buildingIds);
  }

  return levelIds;
};

export type DeviceGraphicResult = {
  deviceId: number;
  graphicId: number;
  crumbs: string[] | null;
};

export const findDeviceAndGraphicIdsByPath = async ({
  companyId,
  searchPath,
  externalId,
}: {
  searchPath: string;
  externalId: string;
  companyId: number;
}): Promise<DeviceGraphicResult | null> => {
  const device = await fetchDeviceByExternalId({ companyId, externalId });

  if (!device) {
    return null;
  }

  const graphic = device.graphics.find((graphic) =>
    searchInGraphicItems({ items: graphic.items, searchPath }),
  );

  if (graphic) {
    const crumbs = searchInGraphicItems({ items: graphic.items, searchPath });
    return {
      deviceId: device.id,
      graphicId: graphic.id,
      crumbs,
    };
  }

  return null;
};

export const searchInGraphicItems = ({
  items,
  searchPath,
  currentPath = [],
}: {
  items: MenuItems[];
  searchPath: string;
  currentPath?: string[];
}): string[] | null => {
  for (const item of items) {
    const crumbs =
      item.path === searchPath
        ? [...currentPath, item.name]
        : searchInGraphicItems({
            items: item.items || [],
            searchPath,
            currentPath: [...currentPath, item.name],
          });

    if (crumbs) {
      return crumbs;
    }
  }
  return currentPath;
};

/** Flatting all parent objects in given level to list */
export const flattenLevelParentToList = (level: Level | null): Level[] => {
  if (!level) return [];

  let currentLevel: Level = level;
  const list: Level[] = [];

  while (currentLevel.parent) {
    list.push(currentLevel);
    currentLevel = currentLevel.parent;
  }
  list.push(currentLevel);
  return list;
};

/** Getting first path object from items in graphics in a recursive manner. For example input=
[{name: "Backgrounds", items: [{ name: "Background",path: "", items: [{ name: "640", path: "background640.htm",items: []}] }]},{ ... }]; */
export const firstPathAndNamesInGraphicItems = (
  graphicItems: MenuItems[],
  names: string[] = [],
): { path: string; names: string[] } => {
  for (const item of graphicItems) {
    if (item.path) {
      return { path: item.path, names: [...names, item.name] };
    }
    return firstPathAndNamesInGraphicItems(item.items, [...names, item.name]);
  }
  return { path: "", names: [] };
};

export const convertToPointValues = (
  puckValueList: PuckValue[],
): {
  pointName: string;
  pointVal: PuckValue;
}[] => {
  const pointValueList: {
    pointName: string;
    pointVal: PuckValue;
  }[] = [];
  puckValueList.forEach((ev) => {
    Object.keys(ev.extension?.control || {}).forEach((paramId) => {
      const paramMap = ev.extension!.control!;
      const pointName = `${ev.pointName}#CONTROL#${paramId}`;
      const pointVal = {
        ...ev,
        pointName,
        value: paramMap[paramId],
      };

      pointValueList.push({ pointName, pointVal });
    });
    (ev.extension?.limits || []).forEach((limit) => {
      const point = {
        ...ev,
        ...limit,
        pointName: `${ev.pointName}:${limit.index}`,
      };
      pointValueList.push({ pointName: ev.pointName, pointVal: point });
    });
    pointValueList.push({ pointName: ev.pointName, pointVal: ev });
  });
  return pointValueList;
};
