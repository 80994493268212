import { useState } from "react";
import { IconButton } from "@fidelix/fx-miranda";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "@lingui/macro";

import { useRequiredParams } from "../utils/url";
import { useDeleteGraphicsMutation } from "../graphics/graphics.mutation";
import GenericModal from "../common/generic-model";

export function DeleteGraphics({
  levelId,
  graphicsId,
}: {
  levelId: number;
  graphicsId: number;
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState<React.ReactNode>(null);
  const { companyId } = useRequiredParams({
    companyId: "number",
  });
  const { mutateAsync } = useDeleteGraphicsMutation();

  const onSuccess = () => {
    navigate(`/company/${companyId}/graphics`);
    queryClient.invalidateQueries({ queryKey: ["level-graphics"] });
  };

  const onDeleteGraphics = async () => {
    await mutateAsync(
      {
        graphicsId,
        companyId,
        levelId,
      },
      { onSuccess },
    );
    setOpenPopup(null);
  };

  const handleOpenPopup = () => {
    setOpenPopup(
      <GenericModal
        onClose={() => setOpenPopup(null)}
        onContinue={onDeleteGraphics}
        title={t`Are You Sure?`}
        text={t`The selected graphic image and all associated files will be permanently deleted.`}
      />,
    );
  };

  return (
    <>
      <IconButton
        size="small"
        variant="filled"
        icon="trash"
        onPress={handleOpenPopup}
      />
      {openPopup}
    </>
  );
}
